import { KcContextCustom } from "../kcContext.d";
import React, { memo } from "react";
import { I18n } from "../messages/i18n";
import {
  Button,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { PageTitle } from "../components/custom/title/PageTitle";
import { saveAccount } from "../api/account";

type AccountProps = {
  kcContext: KcContextCustom.Account;
  i18n: NonNullable<I18n>;
};

const Account = memo(({ kcContext, i18n }: AccountProps) => {
  const { account, locale } = kcContext;
  const { msgStr, advancedMsgStr } = i18n;

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const updateAccount = (formData: {
    [key in string]: FormDataEntryValue;
  }): typeof account => {
    const locale = formData["locale"];
    delete formData["locale"];
    return {
      ...account,
      ...formData,
      attributes: {
        ...account.attributes,
        locale: locale.toString(),
      },
    };
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setIsSubmitting(true);
    e.preventDefault();
    const formData = Object.fromEntries(new FormData(e.currentTarget));
    await saveAccount({ ...updateAccount(formData) });
    window.location.reload();
  };

  const formFields: Array<keyof KcContextCustom.Account["account"]> = [
    "username",
    "email",
    "firstName",
    "lastName",
  ];

  return (
    <Grid container direction="column">
      <PageTitle message="viewProfile" />
      <Grid
        container
        component={Paper}
        direction="column"
        margin="auto"
        padding="24px"
        width="fit-content"
      >
        <Typography variant="h2" paddingBottom="16px">
          {advancedMsgStr("editAccountHtmlTitle")}
        </Typography>
        <Grid
          container
          component="form"
          method="post"
          onSubmit={handleSubmit}
          direction="column"
          spacing={2}
          alignItems="center"
        >
          {formFields.map((field) => (
            <Grid item key={field} display="flex" direction="column">
              <FormLabel>{advancedMsgStr(field)}</FormLabel>
              <TextField
                name={field}
                defaultValue={account[field]}
                disabled={field === "username"}
                sx={{ maxWidth: "500px", width: "60vw" }}
              />
            </Grid>
          ))}
          <Grid
            item
            key="locale"
            display="flex"
            direction="column"
            width="100%"
          >
            <FormLabel id="kc-locale-label">{msgStr("language")}</FormLabel>
            <Select
              labelId="kc-locale-label"
              name="locale"
              type="input"
              fullWidth
              defaultValue={locale?.currentLanguageTag}
            >
              {locale?.supported.map((language) => (
                <MenuItem value={language.languageTag}>
                  {language.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item width="100%">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={isSubmitting}
              disableRipple
            >
              {advancedMsgStr("doSave")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default Account;
