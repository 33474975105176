import { KcContextCustom } from "../kcContext.d";
import { authenticatedFetch } from "./oidc";

export const saveAccount = async (
  account: KcContextCustom.Common["account"]
): Promise<boolean> => {
  return authenticatedFetch("/account/", {
    method: "POST",
    body: JSON.stringify(account),
  }).then((response) => response.ok);
};
