import { createReactOidc } from "oidc-spa/react";
import { createMockReactOidc } from "oidc-spa/mock/react";

const isDevEnv =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

const realm = isDevEnv
  ? "test"
  : window.location.pathname.split("/realms/")[1].split("/")[0];

export const { OidcProvider, useOidc, getOidc } = isDevEnv
  ? createMockReactOidc({
      isUserInitiallyLoggedIn: true,
      publicUrl: undefined,
      isAuthGloballyRequired: true,
    })
  : createReactOidc({
      issuerUri: `${window.location.origin}/auth/realms/${realm}`,
      clientId: "account-console",
      publicUrl: undefined,
      isAuthGloballyRequired: true,
    });

export const authenticatedFetch: typeof fetch = async (path, options) => {
  const oidc = await getOidc();

  return fetch(`${oidc.params.issuerUri}${path}`, {
    ...options,
    headers: {
      ...options?.headers,
      Authorization: `Bearer ${oidc.getTokens().accessToken}`,
      "Content-Type": "application/json",
    },
  });
};
