import React, { memo } from "react";
import { KcContextCustom } from "../kcContext.d";
import { I18n } from "../messages/i18n";

type TotpProps = {
  kcContext: KcContextCustom.Totp;
  i18n: NonNullable<I18n>;
};

const Totp = memo(({ kcContext, i18n }: TotpProps) => {
  const { url } = kcContext;

  React.useEffect(() => {
    window.location.href = url.passwordUrl;
  }, [url.passwordUrl]);

  return <></>;
});

export default Totp;
