import React from "react";
import { AccountCredentialsType } from "../../../types/credentials";
import {
  Button,
  IconButton,
  Paper,
  Stack,
  StackProps,
  Table,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useOidc } from "../../../api/oidc";
import { capitalizeFirst } from "../../../utils/string-utils";
import { deleteCredential } from "../../../api/crendentials";
import { CobaltIcon } from "@docaposte-agility/da-design-system";
import { useKcMessagesContext } from "../../../contexts/KcMessagesContext";

type CredentialDataDisplayProps = StackProps & {
  credential: AccountCredentialsType;
};

const CredentialDataDisplay = ({
  credential,
  ...props
}: CredentialDataDisplayProps) => {
  const { advancedMsgStr } = useKcMessagesContext();
  const { breakpoints } = useTheme();

  const matchesSmDown = useMediaQuery(breakpoints.down("sm"));

  const userCredentialMetadata = credential.userCredentialMetadatas.map(
    (c) => c.credential
  );

  const { goToAuthServer } = useOidc();

  const handleAddAction = () =>
    goToAuthServer({
      extraQueryParams: { kc_action: credential.createAction! },
    });

  const handleDeletionAction = async (id: string) => {
    const success = await deleteCredential(id);
    if (success) window.location.reload();
  };

  const handleUpdateAction = () =>
    goToAuthServer({
      extraQueryParams: { kc_action: credential.updateAction! },
    });

  return (
    <Stack
      component={Paper}
      square
      direction="column"
      spacing={2}
      width="auto"
      padding="24px"
      {...props}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h3">
          {advancedMsgStr(credential.displayName)}
        </Typography>
        {credential.createAction &&
          (matchesSmDown ? (
            <IconButton disableRipple onClick={handleAddAction}>
              <CobaltIcon name="plus" color={"cobalt.bleuDigital100"} />
            </IconButton>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              disableRipple
              startIcon={
                <CobaltIcon name="plus" color={"cobalt.bleuDigital100"} />
              }
              onClick={handleAddAction}
            >
              {advancedMsgStr("add" + capitalizeFirst(credential.type))}
            </Button>
          ))}
      </Stack>
      <Typography variant="body1">
        {advancedMsgStr(credential.helptext)}
      </Typography>{" "}
      <Table
        sx={{
          tableLayout: "fixed",
        }}
      >
        {userCredentialMetadata.length === 0 ? (
          <TableRow>
            <TableCell>
              {advancedMsgStr(
                "no" + capitalizeFirst(credential.type) + "Configured"
              )}
            </TableCell>
          </TableRow>
        ) : (
          userCredentialMetadata.map((cred) => (
            <TableRow>
              <TableCell>
                <Typography key={cred.id} variant="body1">
                  {cred.userLabel || advancedMsgStr(cred.type)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography key={cred.id} variant="body1">
                  {advancedMsgStr(
                    "createdDate",
                    new Date(cred.createdDate).toLocaleDateString()
                  )}
                </Typography>
              </TableCell>
              <TableCell align="right">
                {credential.updateAction && (
                  <IconButton disableRipple onClick={handleUpdateAction}>
                    <CobaltIcon name="edit-2" color="cobalt.bleuDigital100" />
                  </IconButton>
                )}
                {credential.removeable && (
                  <IconButton
                    disableRipple
                    onClick={() => handleDeletionAction(cred.id)}
                  >
                    <CobaltIcon name="trash-2" color="cobalt.redIcon" />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))
        )}
      </Table>
    </Stack>
  );
};

export default CredentialDataDisplay;
