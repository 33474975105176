import React, { memo } from "react";
import { KcContextCustom } from "../kcContext.d";
import { I18n } from "../messages/i18n";
import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { deleteSession } from "../api/sessions";
import { CobaltIcon } from "@docaposte-agility/da-design-system";
import { PageTitle } from "../components/custom/title/PageTitle";

type SessionsProps = {
  kcContext: KcContextCustom.Sessions;
  i18n: NonNullable<I18n>;
};

const Sessions = memo(({ kcContext, i18n }: SessionsProps) => {
  const { sessions } = kcContext.sessions;
  const { msgStr } = i18n;

  const { breakpoints } = useTheme();
  const matchesMdDown = useMediaQuery(breakpoints.down("md"));

  const [sessionList, setSessionList] = React.useState(sessions);

  const handleDelete = (id: string) =>
    deleteSession(id).then((success: boolean) => {
      success && setSessionList(sessionList.filter((s) => s.id !== id));
    });

  return (
    <Stack direction="column">
      <PageTitle message="sessionsHtmlTitle" />
      <TableContainer component={Paper} sx={{ overflow: "auto" }}>
        <Table
          sx={{ width: matchesMdDown ? "auto" : "100%", tableLayout: "fixed" }}
        >
          <TableHead>
            <TableRow>
              <TableCell>{msgStr("ip")}</TableCell>
              <TableCell>{msgStr("started")}</TableCell>
              <TableCell>{msgStr("lastAccess")}</TableCell>
              <TableCell>{msgStr("expires")}</TableCell>
              <TableCell>{msgStr("clients")}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sessionList.map((session, idx) => {
              return (
                <TableRow hover key={idx}>
                  <TableCell>{session.ipAddress}</TableCell>
                  <TableCell>{session.started}</TableCell>
                  <TableCell>{session.lastAccess}</TableCell>
                  <TableCell>{session.expires}</TableCell>
                  <TableCell>{session.clients.join(", ")}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => handleDelete(session.id)}
                      sx={{ color: "red" }}
                      disableRipple
                    >
                      <CobaltIcon name="trash-2" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
});

export default Sessions;
