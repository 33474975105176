import { en_messages as defaultEnMessages } from "@docaposte-agility/da-keycloakify";
import { CustomMessages } from "./CustomMessages";

export const en_messages: CustomMessages = {
  ...defaultEnMessages,

  // HTML Titles
  accountLogHtmlTitle: "Device activity log",
  accountCredentialsHtmlTitle: "Signing in",
  basicAuthenticationHtmlTitle: "Basic authentication",
  twoFactorAuthenticationHtmlTitle: "Two factor authentication",
  "otp-display-name": "Authenticator application",
  "webauthn-display-name": "Security key",

  // Labels
  totp: "TOTP",
  social: "Social",
  credentials: "Credentials",
  civility: "Civility",
  rowsPerPage: "Rows per page",
  linkedAccountUsername: "Linked to user <b>{0}</b>",
  language: "Language",
  doLogout: "Logout",

  // Actions
  viewProfile: "My profile",
  removeCredential: "Delete",
  addOtp: "Add authenticator application",
  addWebauthn: "Add security key",
  addPassword: "Add password",
  noOtpConfigured: "No authenticator application configured",
  noWebauthnConfigured: "No security key configured",
  noAccountLinked: "No account linked",
  noPasswordConfigured: "No password configured",

  // Attributes
  createdDate: "Created on {0}",
  myPersonalInformation: "My personal information",
  myContactDetails: "My contact details",

  // Required actions
  UPDATE_PASSWORD: "Update password",

  // Miscellaneous
  of: "of",
};

export default en_messages;
