import React, { memo } from "react";
import { KcContextCustom } from "../kcContext.d";
import { I18n } from "../messages/i18n";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import LinkedAccountsDataDisplay from "../components/custom/data/LinkedAccountDataDisplay";
import { PageTitle } from "../components/custom/title/PageTitle";
type FederatedIdentityProps = {
  kcContext: KcContextCustom.FederatedIdentity;
  i18n: NonNullable<I18n>;
};

const FederatedIdentity = memo(
  ({ kcContext, i18n }: FederatedIdentityProps) => {
    const { federatedIdentity, url, stateChecker } = kcContext;

    const { breakpoints } = useTheme();
    const matchesSmDown = useMediaQuery(breakpoints.down("sm"));

    return (
      <Grid container direction="column">
        <PageTitle message="linkedAccountsHtmlTitle" />
        <Grid
          container
          direction={matchesSmDown ? "column" : "row"}
          gap={3}
          sx={{ wrap: "wrap" }}
        >
          {federatedIdentity.identities.map((identity) => (
            <LinkedAccountsDataDisplay
              identity={identity}
              socialUrl={url.socialUrl}
              stateChecker={stateChecker}
              removable={federatedIdentity.removeLinkPossible}
              i18n={i18n}
            />
          ))}
        </Grid>
      </Grid>
    );
  }
);

export default FederatedIdentity;
