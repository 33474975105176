import { ThemeProvider } from "@emotion/react";
import { OidcProvider } from "../api/oidc";
import { theme } from "@docaposte-agility/da-design-system";
import { createTheme, CssBaseline } from "@mui/material";
import { I18n } from "../messages/i18n";
import { KcMessagesContextProvider } from "./KcMessagesContext";

type ContextProviderProps = {
  i18n: NonNullable<I18n>;
  children: React.ReactNode | React.ReactNode[] | undefined;
};

const ContextProvider = ({ i18n, children }: ContextProviderProps) => {
  const ddsThemeExtended = createTheme(theme, {
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: theme.palette?.cobalt.light,
          },
        },
      },
    },
  });

  return (
    <OidcProvider>
      <ThemeProvider theme={ddsThemeExtended}>
        <KcMessagesContextProvider i18n={i18n}>
          <CssBaseline />
          {children}
        </KcMessagesContextProvider>
      </ThemeProvider>
    </OidcProvider>
  );
};

export default ContextProvider;
