import React from "react";
import { Grid, GridProps, useMediaQuery, useTheme } from "@mui/material";

type LogoProps = {
  src: HTMLImageElement["src"];
  alt: HTMLImageElement["alt"];
  height?: number | string;
  width?: number | string;
  xs?: GridProps["xs"];
  sm?: GridProps["sm"];
  md?: GridProps["md"];
  lg?: GridProps["lg"];
};

const LogoContainer = ({
  src,
  alt,
  height,
  width,
  ...breakpoints
}: LogoProps) => {
  const theme = useTheme();
  const matchesSmDown = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      item
      display="flex"
      margin="auto"
      justifyContent="center"
      {...breakpoints}
    >
      <img
        src={src}
        alt={alt}
        loading="lazy"
        height={height ?? "auto"}
        width={width ?? "auto"}
        style={{
          maxWidth: matchesSmDown ? "25vw" : "10vw",
        }}
      />
    </Grid>
  );
};

export default LogoContainer;
