import React from "react";
import { AccountCredentialsType } from "../types/credentials";
import { authenticatedFetch } from "./oidc";

const getCredentials = async (): Promise<AccountCredentialsType[]> => {
  return authenticatedFetch("/account/credentials").then((response) =>
    response.json()
  );
};

export const useCredentials = (): AccountCredentialsType[] => {
  const [credentials, setCredentials] = React.useState<
    AccountCredentialsType[]
  >([]);

  React.useEffect(() => {
    getCredentials().then(setCredentials);
  }, []);

  return credentials;
};

export const deleteCredential = async (id: string): Promise<boolean> => {
  return authenticatedFetch(`/account/credentials/${id}`, {
    method: "DELETE",
  }).then((response) => response.ok);
};
