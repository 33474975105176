import React, { memo } from "react";
import { KcContextCustom } from "../kcContext.d";
import { I18n } from "../messages/i18n";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { PageTitle } from "../components/custom/title/PageTitle";

type LogProps = {
  kcContext: KcContextCustom.Log;
  i18n: NonNullable<I18n>;
};

const Log = memo(({ kcContext, i18n }: LogProps) => {
  const { log } = kcContext;
  const { msgStr } = i18n;

  const { breakpoints } = useTheme();
  const matchesMdDown = useMediaQuery(breakpoints.down("md"));

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Stack direction="column">
      <PageTitle message="accountLogHtmlTitle" />
      <TableContainer component={Paper} sx={{ overflow: "auto" }}>
        <Table
          sx={{ width: matchesMdDown ? "auto" : "100%", tableLayout: "fixed" }}
        >
          <TableHead>
            <TableRow>
              <TableCell>{msgStr("date")}</TableCell>
              <TableCell>{msgStr("event")}</TableCell>
              <TableCell>{msgStr("ip")}</TableCell>
              <TableCell>{msgStr("client")}</TableCell>
              <TableCell>{msgStr("details")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {log.events
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((log, idx) => (
                <TableRow hover key={idx}>
                  <TableCell>{log.date.toLocaleString()}</TableCell>
                  <TableCell>{log.event}</TableCell>
                  <TableCell>{log.ipAddress}</TableCell>
                  <TableCell>{log.client}</TableCell>
                  <TableCell>
                    {log.details.map((e) => e.key + ": " + e.value).join(", ")}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                component="td"
                colSpan={5}
                rowsPerPageOptions={[5, 10, 25]}
                count={log.events.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={msgStr("rowsPerPage")}
                labelDisplayedRows={({ from, to, count }) => {
                  return `${from}-${to} ${msgStr("of")} ${count}`;
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Stack>
  );
});

export default Log;
