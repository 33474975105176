import React, { memo } from "react";
import { KcContextCustom } from "../kcContext.d";
import { I18n } from "../messages/i18n";

type ApplicationsProps = {
  kcContext: KcContextCustom.Applications;
  i18n: NonNullable<I18n>;
};

const Applications = memo(({ kcContext, i18n }: ApplicationsProps) => {
  const { url } = kcContext;

  React.useEffect(() => {
    kcContext.applications.applications = [];
    window.location.href = url.accountUrl;
  });

  return <></>;
});

export default Applications;
