import React from "react";
import { I18n } from "../../../messages/i18n";
import { KcContextCustom } from "../../../kcContext.d";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { capitalizeFirst, truncateToLength } from "../../../utils/string-utils";
import { CobaltIcon } from "@docaposte-agility/da-design-system";

type LinkedAccountDataDisplayProps = {
  identity: KcContextCustom.FederatedIdentity["federatedIdentity"]["identities"][number];
  socialUrl: string;
  removable: boolean;
  stateChecker: string;
  i18n: NonNullable<I18n>;
};

const LinkedAccountsDataDisplay = ({
  identity,
  socialUrl,
  removable,
  stateChecker,
  i18n,
}: LinkedAccountDataDisplayProps) => {
  const { advancedMsg, advancedMsgStr } = i18n;

  const isCreating = !identity.connected;
  const isRemoving = identity.connected && removable;

  const getAction = (): string => {
    return isCreating ? "add" : isRemoving ? "remove" : "";
  };

  const hasAction = getAction() !== "";

  return (
    <Grid
      item
      container
      direction="column"
      component={Paper}
      square
      padding="24px"
      flexWrap="wrap"
      flexBasis="30%"
    >
      <Typography variant="h3" paddingBottom="8px">
        {truncateToLength(identity.displayName, 20)}
      </Typography>
      <Grid
        item
        component="form"
        action={socialUrl}
        method="post"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
        xs
      >
        <Typography variant="body2" color={"cobalt.lynch"}>
          {isCreating
            ? advancedMsgStr("noAccountLinked")
            : advancedMsg("linkedAccountUsername", identity.userName)}
        </Typography>
        <Grid
          component="input"
          type="hidden"
          id="providerId"
          name="providerId"
          value={identity.providerId}
        />
        <Grid
          component="input"
          type="hidden"
          id="stateChecker"
          name="stateChecker"
          value={stateChecker}
        />
        {hasAction && (
          <Grid
            component="input"
            type="hidden"
            id="action"
            name="action"
            value={getAction()}
          />
        )}
        {hasAction && (
          <Button
            type="submit"
            variant="text"
            id={getAction() + "-link-" + identity.providerId}
            startIcon={<CobaltIcon name={isCreating ? "plus" : "trash-2"} />}
            disableRipple
            sx={{
              paddingRight: "0px",
              color: isCreating ? "cobalt.bleuDigital100" : "cobalt.redText",
              ":hover": isCreating ? "cobalt.hover" : "cobalt.redBackground",
            }}
          >
            {advancedMsgStr("do" + capitalizeFirst(getAction()))}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default LinkedAccountsDataDisplay;
