import React, { memo } from "react";
import { KcContextCustom } from "../kcContext.d";
import { I18n } from "../messages/i18n";
import { Box, Stack, Typography } from "@mui/material";
import CredentialDataDisplay from "../components/custom/data/CredentialDataDisplay";
import { WaitingScreen } from "@docaposte-agility/da-keycloakify";
import { useCredentials } from "../api/crendentials";
import { PageTitle } from "../components/custom/title/PageTitle";

type PasswordProps = {
  kcContext: KcContextCustom.Password;
  i18n: NonNullable<I18n>;
};

export const Password = memo(({ kcContext, i18n }: PasswordProps) => {
  const { msgStr } = i18n;

  const credentials = useCredentials();

  // If credentials are not loaded yet, show a waiting screen
  if (credentials === null || credentials === undefined) {
    return <WaitingScreen />;
  }

  return (
    <Stack direction="column">
      <PageTitle message="accountCredentialsHtmlTitle" />
      <Box id="basic-authentication-credentials" marginBottom={3}>
        <Typography variant="h2" marginBottom={2}>
          {msgStr("basicAuthenticationHtmlTitle")}
        </Typography>
        {credentials
          .filter((c) => c.category === "basic-authentication")
          .map((cred) => (
            <CredentialDataDisplay credential={cred} />
          ))}
      </Box>
      <Box id="two-factor-credentials">
        <Typography variant="h2" marginBottom={1}>
          {msgStr("twoFactorAuthenticationHtmlTitle")}
        </Typography>
        {credentials
          .filter((c) => c.category === "two-factor")
          .map((cred) => (
            <CredentialDataDisplay credential={cred} marginBottom={2} />
          ))}
      </Box>
    </Stack>
  );
});

export default Password;
