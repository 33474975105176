import React, { memo } from "react";
import { KcContext } from "../kcContext";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { I18n } from "../messages/i18n";
import DesktopHeader from "../components/custom/header/DesktopHeader";
import MobileHeader from "../components/custom/header/MobileHeader";

type TemplateProps = {
  kcContext: KcContext;
  i18n: NonNullable<I18n>;
  children: React.ReactNode | React.ReactNode[];
};

export const Template = memo(({ kcContext, i18n, children }: TemplateProps) => {
  const { breakpoints } = useTheme();
  const matchesSmDown = useMediaQuery(breakpoints.down("sm"));

  return (
    <>
      {matchesSmDown ? (
        <MobileHeader kcContext={kcContext} />
      ) : (
        <DesktopHeader kcContext={kcContext} />
      )}
      <Grid
        container
        component="main"
        direction="column"
        spacing={2}
        width="80vw"
        margin="auto"
      >
        {children}
      </Grid>
    </>
  );
});

export default Template;
