import React, { lazy, memo } from "react";
import { KcContext } from "./kcContext";
import { I18n, useI18n } from "./messages/i18n";
import Fallback from "keycloakify/account";
import { KcContext as KcContextBase } from "keycloakify/account/kcContext";
import {
  Account,
  Applications,
  FederatedIdentity,
  Log,
  Password,
  Sessions,
  Template,
  Totp,
} from "./pages";
import ContextProvider from "./contexts/ContextProvider";

const DefaultPage = lazy(() => import("keycloakify/account/Template"));

type AppProps = {
  kcContext: KcContext;
};

const getPage = (kcContext: KcContext, i18n: NonNullable<I18n>) => {
  switch (kcContext.pageId) {
    case "account.ftl":
      return <Account kcContext={kcContext} i18n={i18n} />;
    case "password.ftl":
      return <Password kcContext={kcContext} i18n={i18n} />;
    case "applications.ftl":
      return <Applications kcContext={kcContext} i18n={i18n} />;
    case "sessions.ftl":
      return <Sessions kcContext={kcContext} i18n={i18n} />;
    case "log.ftl":
      return <Log kcContext={kcContext} i18n={i18n} />;
    case "totp.ftl":
      return <Totp kcContext={kcContext} i18n={i18n} />;
    case "federatedIdentity.ftl":
      return <FederatedIdentity kcContext={kcContext} i18n={i18n} />;
    default:
      return undefined;
  }
};

export const App = memo(({ kcContext }: AppProps) => {
  const i18n = useI18n({ kcContext });

  // i18n not loaded yet
  if (i18n === null) {
    return null;
  }

  const page = getPage(kcContext, i18n);
  if (page !== undefined) {
    return (
      <ContextProvider i18n={i18n}>
        <Template kcContext={kcContext} i18n={i18n}>
          {page}
        </Template>
      </ContextProvider>
    );
  }

  // If page is not redefined by the theme, fall back to keycloakify's default page
  const kcContextStandard = kcContext as KcContextBase;

  return (
    <Fallback
      Template={DefaultPage}
      i18n={i18n}
      kcContext={kcContextStandard}
      doUseDefaultCss={true}
    />
  );
});

export default App;
